// Variable overrides

$orange: #1e9fef;
$white: #fff;
$darkBlue: #05254d;
$blackk: hsl(0, 73%, 45%);
$darkBlue2: #042954;
$lightBlue: #2ad7c5;
$red: #fa6767;
$background: #f0f1f3;
$mobile_device: "(max-width: 767px)";
